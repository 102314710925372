import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { ImgContainer, MiddleContainerImg } from './item.css';

const Item = ({ title, image }) => {
  const intl = useIntl();
  return (
    <Link
      to={'/about#' + title}
      className="w-1/2 pb-2 md:w-1/3 md:pb-10 lg:pb-0 lg:w-1/2 lg:pb-10 xl:flex-1 xl:pb-10"
    >
      <ImgContainer>
        <Img
          fixed={image ? image.childImageSharp.fixed : {}}
          alt={title}
          className={'pane-img'}
        />
        <MiddleContainerImg>
          <span className="text-5xl text-white font-bold">
            {intl.formatMessage({ id: title })}
          </span>
        </MiddleContainerImg>
      </ImgContainer>
    </Link>
  );
};

Item.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
