import styled from 'styled-components';

export const MiddleContainerImg = styled.div`
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`;

export const ImgContainer = styled.div`
  position: relative;
  &:hover img {
    filter: brightness(50%);
    transition: 0.3s ease;
  }
  img {
    transition: 0.3s ease;
  }
`;
