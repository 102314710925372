import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-medium-image-zoom/dist/styles.css';
import ContainerZoomImg from 'components/ContainerZoomImg';
import Layout from 'components/layout/layout';
import Gallery from 'components/paneGallery/gallery';
import Title from 'components/title/title';
import Section from 'components/sections/section';

const activities = [
  {
    title: 'beach-content',
    icon: 'umbrella-beach',
  },
  {
    title: 'shopping-content',
    icon: 'shopping-basket',
  },
  {
    title: 'walk-content',
    icon: 'walking',
  },
  {
    title: 'tunnel-content',
    icon: 'subway',
  },
  {
    title: 'nausicaa-content',
    icon: 'fish',
  },
  {
    title: 'museum-content',
    icon: 'landmark',
  },
];

const Index = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout pageTitle={'Accueil'}>
      <div className="text-center bg-white global-font">
        <Section>
          <Title as="h2" size="large" id={'welcome'}>
            {intl.formatMessage({ id: 'welcome-title' })}
          </Title>
          <div className="mt-20 text-center w-3/4 sm:w-2/3 md:w-1/3 lg:w-1/3 mr-auto ml-auto leading-10 text-gray-600">
            <p>{intl.formatMessage({ id: 'welcome-text-1' })}</p>
            <p> {intl.formatMessage({ id: 'welcome-text-2' })}</p>
            <p>{intl.formatMessage({ id: 'welcome-text-3' })}</p>
            <p> {intl.formatMessage({ id: 'welcome-text-4' })}</p>
            <p> {intl.formatMessage({ id: 'welcome-text-5' })}</p>
            <p className="pt-3 italic">
              {intl.formatMessage({ id: 'welcome-text-6' })}
            </p>
          </div>
        </Section>
        <Section>
          <Title as="h2" size="large" id={'lodge'}>
            {intl.formatMessage({ id: 'lodge-title' })}
          </Title>
          <div className="mt-20">
            <Gallery items={data.homeJson.lodges} />
          </div>
        </Section>
        <Section>
          <Title as="h2" size="large" id={'region'}>
            {intl.formatMessage({ id: 'region-title' })}
          </Title>
          <div className="mt-20 lg:w-1/2 mr-auto ml-auto md:grid md:gap-20 md:grid-cols-3 text-center md:px-10">
            {activities.map(activity => (
              <div key={activity.title} className="py-10">
                <FontAwesomeIcon
                  icon={activity.icon}
                  size={'3x'}
                  className="block mr-auto ml-auto pb-6"
                />
                <div className="mr-auto ml-auto w-2/6 md:w-2/4 lg:w-auto text-gray-600 italic">
                  {intl.formatMessage({ id: activity.title })}
                </div>
              </div>
            ))}
          </div>
        </Section>
        <Section>
          <Title as="h2" size="large" id={'gallery'}>
            {intl.formatMessage({ id: 'gallery-title' })}
          </Title>
          <div className="pt-20 mr-auto ml-auto w-full md:w-3/4 xl:w-1/2">
            {data.homeJson.gallery.map(item => (
              <ContainerZoomImg
                key={item.title}
                img={item.image.childImageSharp.fixed}
                title={item.title}
                relativePath={item.image.relativePath}
                thumbailClass={'m-2 gallery-item '}
                realSizeClass={'gallery-real-size'}
              />
            ))}
          </div>
        </Section>
      </div>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      lodges {
        title
        image {
          childImageSharp {
            fixed(width: 316, height: 630) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      gallery {
        title
        image {
          relativePath
          childImageSharp {
            fixed(width: 220, height: 220) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
